import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Chart from "../static/chart.svg"
import PriceChart from "../components/priceChart"

const IndexPage = ({ pageContext }) => {

    const { name, image, full } = pageContext;

    return <Layout>
        <section class="mt-16" id="features">
            <div class="mx-auto max-w-xl px-4 py-16">
                <article class="mx-auto h-full lg:mx-0 lg:text-left flex align-top flex-col">
                    <h1 class="text-3xl font-bold sm:text-3xl">{name}</h1>
                    <div class="mt-4" dangerouslySetInnerHTML={{ __html: full.replace(/<h2>/g, '<h2 class="font-bold mt-4 text-lg">').replace(/<p>/g, '<p class="text-gray-600 text-justify">') }} />
                </article>
            </div>
        </section>
    </Layout>
}

export const Head = ({ pageContext }) => <Seo title={pageContext.name} children={<>
    <script type="application/ld+json" dangerouslySetInnerHTML={{
        __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            "headline": pageContext.name,
            "image": [
                pageContext.image
            ],
            "datePublished": new Date(pageContext.date).toISOString(),
            "dateModified": new Date(pageContext.date).toISOString(),
            "author": [{
                "@type": "Person",
                "name": pageContext.author?.name,
                "url": `https://twitter.com/${pageContext.author?.twitter}`
            }]
        })
    }} />
</>} />

export default IndexPage
